<template>
  <v-col cols="12" class="pa-2" @click="updateOptions">
    <div class="grey--text" v-if="title">{{ title }}</div>
    <FilePond
      :allow-multiple="maxFiles > 1"
      :max-files="maxFiles"
      label-idle="فایل خود را اینجا رها کنید و یا  <span class='filepond--label-action'>انتخاب کنید</span>"
      :accepted-file-types="fileTypes"
      :files="customFiles"
      imagePreviewMaxHeight="100"
      :maxFileSize="maxFileSize"
      :imageValidateSizeMaxWidth="imageMaxWidth"
      :imageValidateSizeMaxHeight="imageMaxHeight"
      imageValidateSizeLabelImageSizeTooBig="تصویر بیش از حد بزرگ است."
      imageValidateSizeLabelExpectedMaxSize="بیشترین سایز برابر {maxWidth} × {maxHeight}"
      :allowImageValidateSize="allowImageValidateSize"
      @processfile="processfile"
      @removefile="removeFile"
      @activatefile="activatefile"
    />
  </v-col>
</template>

<script>
import vueFilePond, { setOptions } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';

import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import { Constants } from '../constants/GlobalConstants';
import MyAxios from '../constants/MyAxios';

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster,
  FilePondPluginImageValidateSize
);

export default {
  components: {
    FilePond,
  },
  data() {
    return {
      customFiles: [],
    };
  },
  props: {
    value: {
      type: Object,
    },
    files: {
      type: Object,
      default: () => ({}),
    },
    maxFileSize: {
      type: String,
      default: '300MB',
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    fileTypes: {
      type: String,
      default: 'image/jpeg, image/png',
    },
    title: {
      type: String,
      default: null,
    },
    allowImageValidateSize: {
      type: Boolean,
      default: true,
    },
    imageMaxWidth: {
      type: Number,
      default: 65535,
    },
    imageMaxHeight: {
      type: Number,
      default: 65535,
    },
    withDelete: {
      type: Boolean,
      default: true,
    },
    isRemote: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    setOptions(this.options);
  },
  methods: {
    updateOptions(){
      setOptions(this.options);
    },
    processfile(...args) {
      this.$emit('processfile', ...args);
      let response = JSON.parse(args[1].serverId);
      // this.$emit("input", response.id);
      this.media_id = response.id;
    },
    activatefile(val) {
      if (val.serverId) window.open(val.serverId, '_blank');
    },
    removeFile(error, data) {
      if (this.withDelete)
        MyAxios.delete('/media/' + data.file.id + '/delete')
          .then(response => {
            console.log(response.data);
            // this.$emit("input", -1);
            this.media_id = null;
          })
          .catch(error => {
            console.log(error?.response || error);
          });
    },
  },
  computed: {
    options() {
      return {
        server: {
          url: Constants.mainURL,
          process: {
            url: this.isRemote ? '/media/uploadd' : '/media/upload',
            method: 'POST',
            headers: {
              Authorization:
                'Bearer ' +
                (localStorage.getItem('moholandtoken')
                  ? JSON.parse(localStorage.getItem('moholandtoken'))
                      .access_token
                  : ''),
            },
            timeout: 500000,
          },
          revert: null,
        },
      };
    },
    media_id: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    files: {
      immediate: true,
      handler(file) {
        if (
          file &&
          typeof file == 'object' &&
          file.file_name &&
          file.file_type
        ) {
          this.customFiles = [
            {
              source: file.url,
              options: {
                type: 'local',
                file: {
                  id: file.id,
                  name: file.file_name,
                  size: file.file_size,
                  type: file.file_type,
                },
                metadata: {
                  poster: file.url,
                },
              },
            },
          ];
        } else this.customFiles = [];
      },
    },
    value: {
      immediate: true,
      handler(val) {
        if (val && typeof val == 'object' && val.file_name && val.file_type) {
          this.customFiles = [
            {
              source: val.url,
              options: {
                type: 'local',
                file: {
                  id: val.id,
                  name: val.file_name,
                  size: val.file_size,
                  type: val.file_type,
                },
                metadata: {
                  poster: val.url,
                },
              },
            },
          ];
        }
      },
    },
  },
};
</script>

<style>
.filepond--credits {
  display: none !important;
}
.filepond--hopper {
  margin-bottom: 0 !important;
}
</style>
